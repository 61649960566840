
















































































































































































































































































































.status-filter{
  text-transform: capitalize;
}
.big-numbers{
  font-size: 3.5em;
  font-weight: bold;
  line-height: 1.25em;
  &.smaller{
    margin-top: 7px;
    font-size: 2.5em;
    color:#548235;
  }
  small{
    color: #737373;
    font-size: 14px;
  }
  i.uil{
    font-size: .75em;
    margin-left: -15px;
  }
}
.stat-head{
  margin-top: 10px;
  font-weight: bold;
  color: #737373;
  font-size: 1.4em;
}
.stat-foot{
  color: #17244d;
  font-size: 1.3em;
  margin-bottom: 10px;
}
.text-darker-blue{
  color: #17244d;
}

